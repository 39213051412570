import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import {
  createSupplierValidationRecordAccountVerificationAllUpdateFieldsFromRecord,
  createSupplierValidationRecordSupplierVerificationAllUpdateFieldsFromRecord,
  SupplierValidationAccountVerificationRecord,
  SupplierValidationPayeeVerificationRecord,
  SupplierValidationPayeeWithAccountsVerification,
  SupplierValidationRecord,
  SupplierValidationRecordStatus,
  SupplierValidationRecordStatusSubsets,
  SupplierValidationVerificationRecordEvidence,
  transformSupplierValidationRecord,
} from '@mortee/domain/validationSystem';
import CreateVerificationDataRecordPayeeForm from '@mortee/routes/validationSystem/editValidtionRecord/mainTabs/verification/VerificationRecordPayeeForm';
import CreateVerificationDataRecordAccountForm from '@mortee/routes/validationSystem/editValidtionRecord/mainTabs/verification/VerificationRecordAccountForm';
import Button from '@app/components/Button';
import { arrayWithoutValue, replaceOrInsertValue } from '@app/utils/arrayUtils';
import validationSystemServices from '@mortee/services/validationSystemServices';
import { showCustomModalAsync } from '@app/components/Modal';
import ModalAppContext from '@app/ModalAppContext';
import useModalContext from '@app/hooks/useModalContext';
import VerificationReviewWarningsModal from '@mortee/routes/validationSystem/editValidtionRecord/mainTabs/verification/reviewModals/VerificationReviewWarningsModal';
import useAppStores from '@app/hooks/useAppStores';
import MorteeMode from '@mortee/morteeMode';
import useInfraStores from '@app/hooks/useInfraStores';
import { shoot } from '@app/utils/messageLauncher';
import ConditionalTooltip from '@app/components/ConditionalTooltip';
import VerificationPayeeAndAccountsReviewAndCompleteModal from '@mortee/routes/validationSystem/editValidtionRecord/mainTabs/verification/reviewModals/VerificationReviewAndCompleteModal';
import VerificationReviewAndChangeToWaitingForReviewModal from '@mortee/routes/validationSystem/editValidtionRecord/mainTabs/verification/reviewModals/VerificationReviewAndChangeToWaitingForReviewModal';
import {
  ActionsContainer,
  SidePadding,
  TabTitle,
} from '@mortee/routes/validationSystem/editValidtionRecord/EditValidationRecordStyles';
import VerticalShadowScroller from '@app/components/VerticalShadowScroller';
import { HasAlertsContext } from '@mortee/routes/validationSystem/editValidtionRecord/EditValidationRecordForm';
import { ValidatedPayeeAccountValidationStatusSupplierValidationVerification } from '@app/domain/validatedPayeeAccount';
import { SupplierRegistrationProcessInstructionType } from '@app/domain/commonSupplierRegistration';
import { extractSupplierValidationRecordCurrentInstructionType } from '@app/utils/utils';

interface Props {
  isPendingApprovalAndDoesntHaveApprovalRole: boolean;
  record: SupplierValidationRecord;
  verificationRecordData: SupplierValidationPayeeWithAccountsVerification | null;
  evidenceData: SupplierValidationVerificationRecordEvidence | null | undefined;
  className?: string;
  onSaved(
    newVerification: SupplierValidationPayeeWithAccountsVerification | undefined,
    updatedSupplierValidationRecord: SupplierValidationRecord | undefined,
  ): void;
  onIsFormUnsavedChanged(isFormUnsaved: boolean): void;
}

const VerificationRecordDataTabContent: FC<Props> = observer((props) => {
  const {
    verificationRecordData,
    onSaved,
    onIsFormUnsavedChanged,
    evidenceData,
    record,
    isPendingApprovalAndDoesntHaveApprovalRole,
    className,
  } = props;
  const { userStore, navigationStore } = useInfraStores<MorteeMode>();
  const { validatedPayeesManagementStore } = useAppStores<MorteeMode>();
  const modalContext = useModalContext();

  const { permissionsStore } = useInfraStores<MorteeMode>();

  const accountRunningTempIndex = useRef<number>(0);

  const [formData, setFormData] = useState<FormsData>(() => ({
    payee: !!verificationRecordData?.payee,
    accounts:
      verificationRecordData?.accounts.map((account) => ({
        key: account.staticId,
        staticId: account.staticId,
        hasUnsavedChanges: false,
        baseData: account,
      })) ?? [],
  }));

  const hasOpenAlerts = useContext(HasAlertsContext);

  const formHasChanges = formData.payee || formData.accounts.some((account) => account.hasUnsavedChanges);
  const formHasSavedData = hasPayeeData() || hasAccountData();

  const currentInstructionType = extractSupplierValidationRecordCurrentInstructionType(record);
  const isLyonsInstruction = currentInstructionType === SupplierRegistrationProcessInstructionType.bankValidationLyons;
  const isLyonsValidationType = formData.accounts.some(
    (account) => account.baseData?.type === ValidatedPayeeAccountValidationStatusSupplierValidationVerification.lyonsValidation,
  );
  const isPrivatePayee = verificationRecordData?.payee?.isPrivate;
  const isLyonsAndNotPrivate = (isLyonsInstruction || isLyonsValidationType) && !isPrivatePayee;

  function disableCompleteButton(): boolean {
    return formHasChanges || !formHasSavedData || record.isClosurePerformed || hasOpenAlerts || isLyonsAndNotPrivate;
  }

  function getConditionalTooltipProps(): { showTooltip: boolean; title: string; placement: 'top' } {
    const title = isLyonsAndNotPrivate
      ? 'Lyons validation can only be completed for private payees'
      : record.isClosurePerformed
      ? 'This verification has already been distributed'
      : '';

    return {
      showTooltip: !!title,
      title,
      placement: 'top',
    };
  }

  function hasPayeeData(): boolean {
    const payeeData = verificationRecordData?.payee;
    if (!payeeData) return false;

    const verificationFields = createSupplierValidationRecordSupplierVerificationAllUpdateFieldsFromRecord(payeeData);
    return Object.values(verificationFields).some((value) => value !== null && value !== false);
  }

  function hasAccountData(): boolean {
    for (const account of formData.accounts) {
      const accountData = account.baseData;
      if (accountData) {
        const verificationFields = createSupplierValidationRecordAccountVerificationAllUpdateFieldsFromRecord(accountData);
        if (Object.values(verificationFields).some((value) => value !== null && value !== false)) {
          return true;
        }
      }
    }
    return false;
  }

  useEffect(() => {
    onIsFormUnsavedChanged(formHasChanges);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- onIsFormUnsavedChanged can change all the time
  }, [formHasChanges]);

  function addNewAccountForm(shouldCopyAccount: boolean, accountKey?: string | number): void {
    accountRunningTempIndex.current = accountRunningTempIndex.current + 1;
    setFormData(
      (currentFormData): FormsData => {
        const existingAccount = verificationRecordData?.accounts.find((account) => account.staticId === accountKey);
        return {
          ...currentFormData,
          accounts: [
            ...formData.accounts,
            {
              key: accountRunningTempIndex.current,
              staticId: null,
              baseData: shouldCopyAccount && existingAccount ? existingAccount : null,
              hasUnsavedChanges: false,
            },
          ],
        };
      },
    );
  }

  async function onAccountDeleted(accountKey: string | number): Promise<void> {
    const account = formData.accounts.find((account) => account.key === accountKey);

    if (!account) {
      return;
    }

    setFormData((currentFormData) => {
      return {
        ...currentFormData,
        accounts: arrayWithoutValue(currentFormData.accounts, account, (a1, a2) => a1.key === a2.key),
      };
    });

    if (typeof accountKey === 'string') {
      onSaved(
        {
          payee: verificationRecordData?.payee || null,
          accounts: verificationRecordData?.accounts.filter((account) => account.staticId != accountKey) || [],
        },
        undefined,
      );
    }
  }

  function onPayeeSaved(payeeData: SupplierValidationPayeeVerificationRecord): void {
    setFormData((currentFormData) => ({
      payee: false,
      accounts: currentFormData.accounts,
    }));

    onSaved(
      {
        payee: payeeData,
        accounts: verificationRecordData?.accounts || [],
      },
      undefined,
    );
  }

  function onAccountSaved(accountKey: string | number, accountData: SupplierValidationAccountVerificationRecord): void {
    const newAccount: SavedAccountFormData = {
      key: accountData.staticId,
      staticId: accountData.staticId,
      baseData: accountData,
      hasUnsavedChanges: false,
    };

    setFormData((currentFormData) => {
      return {
        payee: currentFormData.payee,
        accounts: replaceOrInsertValue(currentFormData.accounts, newAccount, (existing) => existing.key === accountKey),
      };
    });

    onSaved(
      {
        payee: verificationRecordData?.payee || null,
        accounts: replaceOrInsertValue(
          verificationRecordData?.accounts || [],
          accountData,
          (arrItem) => arrItem.staticId === accountData.staticId,
        ),
      },
      undefined,
    );
  }

  function onPayeeFormUnsavedChanged(newValue: boolean): void {
    setFormData((currentFormData) => {
      return {
        ...currentFormData,
        payee: newValue,
      };
    });
  }

  function onAccountFormUnsavedChanged(newValue: boolean, accountKey: string | number): void {
    setFormData((currentFormData) => {
      const matchingAccountIndex = currentFormData.accounts.findIndex((x) => x.key === accountKey);
      const matchingAccount = currentFormData.accounts[matchingAccountIndex];
      const newAccountsArray = [...currentFormData.accounts];

      // Apply the hasUnsavedChanges boolean to the account
      // Does not matter if this account is already saved or not
      newAccountsArray[matchingAccountIndex] = {
        ...matchingAccount,
        hasUnsavedChanges: newValue,
      };

      return {
        ...currentFormData,
        accounts: newAccountsArray,
      };
    });
  }

  async function handleCompleteClick(): Promise<void> {
    const payeeWithAccountsWarnings = await validatedPayeesManagementStore.calcPayeeAndAccountsCreationAlerts(
      record.staticId,
      true,
    );

    const shouldSave = await showCustomModalAsync(
      (onAccept, onDecline) => {
        return (
          <ModalAppContext {...modalContext}>
            <VerificationPayeeAndAccountsReviewAndCompleteModal
              payeeWithAccountsWarnings={payeeWithAccountsWarnings}
              supplierValidationRecord={record}
              onConfirm={onAccept}
              onClose={onDecline}
            />
          </ModalAppContext>
        );
      },
      { maskClosable: true },
    );

    if (!shouldSave) {
      return;
    }

    const completeMessageKey = `${record.staticId}-complete-verification`;
    const editValidationPath = navigationStore.generateSupplierValidationRecordsTablePageWithEditValidationModalHref(
      record.staticId,
    );

    const loadingMessage = shoot(
      { type: 'loading', key: completeMessageKey, duration: null },
      <div>
        <a href={editValidationPath} target='_blank' rel='noreferrer'>
          Verification of {record.presentationId}
        </a>{' '}
        - Completing
      </div>,
    );

    try {
      await validationSystemServices.completeSupplierValidationVerificationRecord(
        record.staticId,
        record.presentationId,
        editValidationPath,
      );
      onSaved(undefined, undefined);

      shoot(
        { type: 'success', closeable: true, key: completeMessageKey },
        <div>
          <a href={editValidationPath} target='_blank' rel='noreferrer'>
            Verification of {record.presentationId}
          </a>{' '}
          - Completed!
        </div>,
      );
    } catch {
      loadingMessage();
    }
  }

  async function handleWaitingForApprovalClick(): Promise<void> {
    const payeeWithAccountsWarnings = await validatedPayeesManagementStore.calcPayeeAndAccountsCreationAlerts(
      record.staticId,
      true,
    );

    const shouldSave = await showCustomModalAsync(
      (onAccept, onDecline) => {
        return (
          <ModalAppContext {...modalContext}>
            <VerificationReviewAndChangeToWaitingForReviewModal
              payeeWithAccountsWarnings={payeeWithAccountsWarnings}
              supplierValidationRecord={record}
              onConfirm={onAccept}
              onClose={onDecline}
            />
          </ModalAppContext>
        );
      },
      { maskClosable: true },
    );

    if (!shouldSave) {
      return;
    }

    const completeMessageKey = `${record.staticId}-waiting-for-approval-verification`;
    const loadingMessage = shoot(
      {
        type: 'loading',
        key: completeMessageKey,
        duration: null,
      },
      <div>
        <a
          href={navigationStore.generateSupplierValidationRecordsTablePageWithEditValidationModalHref(record.staticId)}
          target='_blank'
          rel='noreferrer'
        >
          {record.presentationId}
        </a>{' '}
        - Changing status to "Waiting for approval"
      </div>,
    );
    try {
      const supplierValidationRecordWithWarningsServerResponse = await validationSystemServices.updateValidationRecord(
        record.staticId,
        {
          status: {
            content: {
              value: SupplierValidationRecordStatus.waitingForApproval,
              cancelReasonId: null,
            },
          },
        },
      );

      const updatedSupplierValidationRecord = transformSupplierValidationRecord(
        supplierValidationRecordWithWarningsServerResponse.record,
        userStore.user?.id,
      );

      onSaved(undefined, updatedSupplierValidationRecord);

      shoot(
        { type: 'success', closeable: true, key: completeMessageKey },
        <div>
          <a
            href={navigationStore.generateSupplierValidationRecordsTablePageWithEditValidationModalHref(record.staticId)}
            target='_blank'
            rel='noreferrer'
          >
            {record.presentationId}
          </a>{' '}
          - Updated
        </div>,
      );
    } catch {
      loadingMessage();
    }
  }

  async function handleCheckWarningsClick(): Promise<void> {
    const payeeWithAccountsWarnings = await validatedPayeesManagementStore.calcPayeeAndAccountsCreationAlerts(
      record.staticId,
      true,
    );

    await showCustomModalAsync(
      (onDone) => {
        return (
          <ModalAppContext {...modalContext}>
            <VerificationReviewWarningsModal
              payeeWithAccountsWarnings={payeeWithAccountsWarnings}
              supplierValidationRecord={record}
              onClose={onDone}
            />
          </ModalAppContext>
        );
      },
      { maskClosable: true },
    );
  }

  return (
    <MainContainer id='create-verification-record-process-data' className={className}>
      <ScrollingList>
        <Expanders>
          <TabTitle>Verification</TabTitle>
          <CreateVerificationDataRecordPayeeForm
            evidenceData={evidenceData}
            supplierValidationRecord={record}
            key='payee'
            verificationRecordPayeeData={verificationRecordData?.payee ?? null}
            onIsFormUnsavedChanged={(newValue): void => onPayeeFormUnsavedChanged(newValue)}
            onSaved={(payeeData): void => onPayeeSaved(payeeData)}
          />
          {formData.accounts.map((account, index) => (
            <CreateVerificationDataRecordAccountForm
              evidenceData={evidenceData}
              supplierValidationRecord={record}
              accountStaticId={account.staticId}
              key={account.key}
              displayIndex={index + 1}
              verificationRecordAccountData={account.baseData}
              onIsFormUnsavedChanged={(newValue): void => onAccountFormUnsavedChanged(newValue, account.key)}
              onSaved={(accountData): void => onAccountSaved(account.key, accountData)}
              onDeleted={(): Promise<void> => onAccountDeleted(account.key)}
              onDuplicate={(): void => addNewAccountForm(true, account.key)}
            />
          ))}
          <AddAccountButton
            id='verification-add-account'
            appearance='text'
            onClick={(): void => addNewAccountForm(false)}
            disabled={isPendingApprovalAndDoesntHaveApprovalRole}
          >
            + ADD NEW ACCOUNT
          </AddAccountButton>
        </Expanders>
      </ScrollingList>
      <ActionsContainer>
        <Button
          disabled={formHasChanges || !formHasSavedData}
          id='btn-create-verification-process-check-warnings'
          onClick={(): Promise<void> => handleCheckWarningsClick()}
          appearance='text'
        >
          CHECK WARNINGS
        </Button>
        {permissionsStore.isValidationApprover && record.status.value === SupplierValidationRecordStatus.waitingForApproval && (
          <ConditionalTooltip {...getConditionalTooltipProps()}>
            <Button
              disabled={disableCompleteButton()}
              id='btn-create-verification-process-complete'
              onClick={(): Promise<void> => handleCompleteClick()}
            >
              COMPLETE
            </Button>
          </ConditionalTooltip>
        )}
        {SupplierValidationRecordStatusSubsets.preWaitingApproval.includes(record.status.value) && (
          <ConditionalTooltip
            showTooltip={record.isClosurePerformed}
            title='This verification has already been distributed'
            placement='top'
          >
            <Button
              disabled={formHasChanges || !formHasSavedData || record.isClosurePerformed}
              id='btn-create-verification-process-ready-for-approval'
              onClick={(): Promise<void> => handleWaitingForApprovalClick()}
            >
              READY FOR REVIEW
            </Button>
          </ConditionalTooltip>
        )}
      </ActionsContainer>
    </MainContainer>
  );
});

export default VerificationRecordDataTabContent;

interface FormsData {
  payee: boolean;
  accounts: AccountFormData[];
}

type NotSavedAccountFormData = {
  key: number;
  staticId: null;
  baseData: null | SupplierValidationAccountVerificationRecord;
  hasUnsavedChanges: boolean;
};

type SavedAccountFormData = {
  key: string;
  staticId: string;
  baseData: SupplierValidationAccountVerificationRecord;
  hasUnsavedChanges: boolean;
};

type AccountFormData = NotSavedAccountFormData | SavedAccountFormData;

const MainContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
`;

const ScrollingList = styled(VerticalShadowScroller)`
  flex: 1;
`;

const Expanders = styled(SidePadding)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const AddAccountButton = styled(Button)`
  align-self: flex-start;
`;
